.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  max-width: 1800px;
}

/* Ajustes para la sección de texto */
.text-section {
  width: 50%;
  padding-right: 2rem;
}

.text-section h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #ffd16e;
}

.text-section p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.text-section ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-section ul li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.text-section ul li .icon {
  color: #ffd16e;
  margin-right: 0.5rem;
}

/* Botón de llamada a la acción */
.cta-button {
  background-color: #ffd16e;
  color: black;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 2rem;
}

/* Sección de imagen */
.image-section {
  width: 45%;
}

.image-section img {
  width: 100%;
  border-radius: 2px;
  border: solid 6px #FFD16F;
}

/* Media Queries para dispositivos móviles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  .text-section {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .text-section h1 {
    font-size: 2rem;
  }

  .text-section p,
  .text-section ul li {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
  }

  /* Ocultar la imagen en pantallas móviles */
  .image-section {
    display: none;
  }
}

/* Media Queries para dispositivos aún más pequeños */
@media (max-width: 480px) {
  .container {
    padding: 0.5rem;
  }

  .text-section h1 {
    font-size: 1.8rem;
  }

  .text-section p,
  .text-section ul li {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
}
