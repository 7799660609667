.services-grid {
    display: grid;
    justify-content: space-around;
    margin-top: 5em;
    justify-items: center; /* Centra los elementos hijos dentro de cada celda */
    align-items: center;
    padding: 0 8rem;
    grid-template-columns: repeat(2, 1fr);
}

.service {
    background-color: white;
    color: black;
    width: 80%;
    height: 80%;
    min-width: 40%;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

.service img {
    max-width: 100px;
    min-width: 100px;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 10px;
}

.italic {
    font-size: 20px;
    font-family: 'Montserrat Light';
    text-align: justify;
}

/* Media Queries para dispositivos medianos (tablets) */
@media (max-width: 768px) {
    .services-grid {
        padding: 0 2rem; /* Reduce el padding para pantallas más pequeñas */
        grid-template-columns: 1fr; /* Una columna en tablets y pantallas pequeñas */
    }

    .service {
        width: 80%; /* Incrementa el ancho para tablets */
    }
}

/* Media Queries para dispositivos pequeños (teléfonos) */
@media (max-width: 480px) {
    .services-grid {
        padding: 0 1rem; /* Aún menos padding en pantallas pequeñas */
        grid-template-columns: 1fr; /* Mantener una sola columna en pantallas pequeñas */
    }

    .service {
        width: 100%; /* Ocupa todo el ancho de la pantalla */
    }

    .italic {
        font-size: 16px; /* Reduce el tamaño de la fuente en pantallas pequeñas */
    }
}
