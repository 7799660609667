.desktop-sections {
    display: none;
}

@media (min-width: 768px) {
    .desktop-sections {
        display: flex;
    }
}

.logo {
    height: 50px;
}