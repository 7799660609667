.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10rem; /* Se mantendrá para pantallas grandes */
  color: black;
  font-family: 'KGManhattanScript', sans-serif;
}

.title {
  font-size: 4rem;
  position: relative;
  white-space: nowrap; /* Este se quitará en pantallas pequeñas */
  padding: 0;
  margin: 0;
  text-decoration: underline;
  text-decoration-color: #ffd16e;
}

/* Media Queries para dispositivos móviles */
@media (max-width: 768px) {
  .title-container {
    padding-left: unset; /* Reducir el padding en pantallas medianas */
    align-items: center; /* Centrar el título en pantallas pequeñas */
  }

  .title {
    font-size: 3rem; /* Reducir el tamaño de la fuente en pantallas medianas */
    white-space: normal; /* Permitir que el texto se ajuste en varias líneas */
    text-align: center; /* Centrar el texto en pantallas medianas */
  }
}

/* Media Queries para pantallas pequeñas (teléfonos) */
@media (max-width: 480px) {
  .title-container {
    padding-left: unset; /* Más pequeño padding en pantallas pequeñas */
    align-items: center;
  }

  .title {
    font-size: 2.5rem; /* Aún más pequeño para pantallas de teléfonos */
    white-space: normal;
    text-align: center;
  }
}
