/* src/components/MainButton.css */
.neon-button {
    margin-top: 15px;
    z-index: 1;
    padding: 10px 30px;
    font-family: 'Montserrat Black';
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5em;
    border: 1px solid black;
    outline: none;
    border-radius: 15px;
    background: #ffd16e;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    color: black;
}

.neon-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    width: 100%;
    height: 100%;
    background: rgba(255, 209, 110, 0.2);
    border-radius: 30px;
    z-index: -1;
    filter: blur(20px);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.neon-button:hover::after {
    opacity: 1;
}

.neon-button:hover {
    background: rgba(255, 209, 110, 0.8);
}

.neon-button:focus,
.neon-button:active {
    outline: none;
    background: rgba(255, 209, 110, 0.6);
}

