/* Estilos generales para el contenedor */
.gallery-container {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 40px !important;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border-radius: 10px;
  border: 5px solid #ffd16e; /* Color dorado para el borde */
}

/* Estilo para las imágenes grandes */
.image-gallery-slide img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Ajustes para las miniaturas */
.image-gallery-thumbnails-wrapper {
  padding: 10px 0;
}

.image-gallery-thumbnail img {
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}

.image-gallery-thumbnail img:hover {
  transform: scale(1.1); /* Efecto zoom al pasar sobre la miniatura */
}

/* Estilo para los botones de navegación */
.image-gallery-icon {
  background-color: #ffd16e; /* Fondo dorado para las flechas */
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-gallery-icon:before {
  color: white; /* Color de la flecha */
}



/* Efecto hover para las flechas */
.image-gallery-icon:hover {
  background-color: #ffd16e; /* Cambia el fondo al pasar sobre el botón */
}

.image-gallery-fullscreen-button, 
.image-gallery-svg, 
.image-gallery-play-button, 
.image-gallery-svg {
  display: flex;
}