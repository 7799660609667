.footer {
  background: #ffd16e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 10%;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 2px solid black;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.footer-logo p {
  font-family: 'Montserrat Italic';
  font-size: 20px;
  color: black;
}

.footer-logo img {
  max-width: 100%;
  height: auto;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  gap: 150px;
}

.footer-column {
  color: black;
  font-family: 'Montserrat Light';
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  font-size: 20px;
}

.footer-column h4 {
  font-family: 'Montserrat';
  margin-bottom: 10px;
  font-size: 25px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 15px;
}

.social-media-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.social-media-icons img {
  width: 40px;
  margin: 0 10px;
}

.footer a {
  color: black;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content {
    justify-content: center;
    align-items: center;
    padding: 20px 5%;
    /* Reduce el padding lateral */
  }

  .footer-logo {
    margin-bottom: 20px;
    /* Añade espacio entre el logo y las columnas */
  }

  .footer-columns {
    flex-direction: column;
    gap: 30px;
    /* Aumenta el espacio entre columnas */
  }

  .footer-column {
    align-items: center;
    text-align: center;
    margin: 0;
    /* Elimina márgenes laterales para aprovechar más espacio */
  }

  .social-media-icons {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    /* Añade margen al final del footer */
  }
}

/* Estilos para móviles */
@media (max-width: 480px) {
  .footer-content {
    justify-content: center;
    align-items: center;
    padding: 20px 5%;
    /* Reduce aún más el padding lateral */
  }

  .footer-logo p {
    font-size: 18px;
    /* Ajusta el tamaño del texto para que sea legible */
  }

  .footer-columns {
    gap: 20px;
    /* Reduce el espacio entre las columnas para dispositivos muy pequeños */
  }

  .footer-column h4 {
    font-size: 20px;
    /* Reduce el tamaño del encabezado para dispositivos móviles */
  }

  .footer-column {
    font-size: 16px;
    /* Reduce el tamaño de la fuente para ahorrar espacio */
  }

  .social-media-icons img {
    width: 40px;
    /* Reduce el tamaño de los iconos para dispositivos móviles */
    margin: 0 8px;
    /* Ajusta el margen entre iconos */
  }

  .social-media-icons {
    margin-bottom: 10px;
    /* Reduce el margen al final del footer */
  }
}