.intro-section {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    text-align: center;
    padding: 0;
    background-image: url('../../assets/images/intro.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 3rem 0;
}

.intro-section {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    text-align: center;
    padding: 0;
    background-image: url('../../assets/images/intro.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 3rem 0;
}

/* Ajustes para dispositivos móviles (pantallas pequeñas) */
@media (max-width: 768px) {
    .intro-section {
        background-attachment: scroll; /* Cambia el attachment para pantallas pequeñas */
        background-size: cover; /* Asegura que cubra toda la pantalla */
        background-position: center center; /* Centra el fondo de manera adecuada */
        padding: 2rem 1rem; /* Ajusta el padding para pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .intro-section {
        background-attachment: scroll; /* Elimina el efecto "fixed" en móviles */
        background-size: cover; /* Usa "contain" para que el fondo se ajuste sin recortar */
        background-position: center top; /* Alinea mejor en pantallas muy pequeñas */
        padding: 1.5rem 1rem; /* Ajusta el padding para dispositivos muy pequeños */
    }
}

.intro-section h1 {
    font-size: 6rem;
    font-family: 'KGManhattanScript';
    color: white;
    margin-bottom: 40px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.intro-container {
    padding-left: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0 5em;
}

.intro-title-container {
    border: 0.2rem solid white;
    padding: 1rem;
    max-width: 900px;
    width: 100%;
}

/* Media Queries para dispositivos de tamaño medio (tablets) */
@media (max-width: 768px) {
    .intro-section {
        align-items: center;
        text-align: center;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .intro-section h1 {
        font-size: 5vw; /* Cambiado para reducir en pantallas medianas */
        margin: 10%;
    }

    .intro-container {
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center;
        margin: 0;
    }
}

/* Media Queries para dispositivos móviles pequeños */
@media (max-width: 480px) {
    .intro-section {
        padding: 1rem 0;
    }

    .intro-title-container {
        white-space: normal;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    .intro-container {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 0;
        text-align: center;
    }

    .intro-section h1 {
        font-size: 8vw; /* Ajustado para pantallas pequeñas */
        margin-bottom: 20px;
    }
}

/* Media Queries para dispositivos muy pequeños (414px o menos) */
@media (max-width: 414px) {
    .intro-section {
        padding: 1rem 0;
    }

    .intro-title-container {
        border: 0.2rem solid white;
        padding: 0.2rem;
        max-width: 350px;
        width: 100%;
    }

    .intro-section h1 {
        font-size: 9vw; /* Ajustado para pantallas más pequeñas */
    }

    .intro-container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .intro-title-container {
        padding: 0.5rem;
    }
}
