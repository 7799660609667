.services-section {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: white;
    justify-content: space-around;
    padding-top: 10rem;
}

@media (max-width: 768px) {
    .services-section {
        padding-top: 5rem; /* Ajustar el padding en tablets */
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 480px) {
    .services-section {
        min-height: 100vh;
        height: 100%;
        padding-top: 3rem; /* Ajustar el espacio entre el título y la galería en móviles */
        padding-left: 1rem;  /* Añadir padding para los lados en móviles */
        padding-right: 1rem;
        padding-bottom: 3rem; /* Añadir un padding inferior */
    }
}
