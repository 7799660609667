body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Italic';
  src: local('Montserrat Italic'), url('./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black';
  src: local('Montserrat Black'), url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light';
  src: local('Montserrat Light'), url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'KGManhattanScript';
  src: local('KGManhattanScript'), url('./assets/fonts/KGManhattanScript.ttf') format('truetype');
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
